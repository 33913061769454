import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import './InfoModal.scss';

const Timeline = () => {
	return (
		<div className='container'>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2013</div>
				<div>
					<b>National Palace Museum</b>
					<br />
					QiangLong C.H.A.O New Media Art, Interactive Installation
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2013</div>
				<div>
					<b>National Palace Museum</b>
					<br />
					QiangLong C.H.A.O New Media Art, Interactive Installation
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2013</div>
				<div>
					<b>Digital Art Festival Taipei</b>
					<br />
					Taipei
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2014</div>
				<div>
					<b>The 12nd Taoyuan Creation Award</b>
					<br />
					Taoyuan
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2017</div>
				<div>
					<b>Diaspora</b>
					<br />
					Asia Contamperary Art Platform - NON Berlin
					<br />
					Berlin
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2018</div>
				<div>
					<b>CosmoProf Asia (Cooperate with cosmetic brand "VesCir")</b>
					<br />
					Hong Kong
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2019</div>
				<div>
					<b>PANORAMA Shenzhen New Media Art Festival</b>
					<br />
					Shenzhen
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2019</div>
				<div>
					<b>Beyound Gender</b>
					<br />
					Factory Betlin
					<br />
					Berlin
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2019</div>
				<div>
					<b>Asia Now (Sponsored by "VesCir")</b>
					<br />
					Asian Art Fair
					<br />
					Paris
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2020</div>
				<div>
					<b>STRANGE THINGS</b>
					<br />
					Silent Green Berlin
					<br />
					Berlin
				</div>
			</Parallax>
			<Parallax translateX={['-100px', '0px']} easing='easeOutExpo' className='cv-table'>
				<div>2020</div>
				<div>
					<b>re:publica Campus</b>
					<br />
					<br />
					Berlin
				</div>
			</Parallax>
		</div>
	);
};

export default Timeline;
