/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useContext } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { Context } from '../../contexts';

const Canvas = () => {
	const canvaRef = useRef(null);
	const renderer = useRef(new THREE.WebGLRenderer({ antialias: true, alpha: true })).current;
	const loader = useRef(new GLTFLoader()).current;
	const scene = useRef(new THREE.Scene()).current;
	const clock = useRef(new THREE.Clock()).current;
	const camera = useRef(new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 4000)).current;

	let body, mixer;

	const { setLoaded, startLoading } = useContext(Context);

	const animate = () => {
		update();
	};
	const init = () => {
		if (canvaRef.current.querySelector('canvas') || scene.getObjectByName('body')) return;
		canvaRef.current.appendChild(renderer.domElement);

		//Setup 3D Modal
		loader.load('./models/scene.glb', function (object3d) {
			setLoaded();
			try {
				body = object3d.scene;
				camera.lookAt(new THREE.Vector3(0, 0.3, 3.6));

				//Setup Light
				let light = new THREE.AmbientLight(0xffffff, 1.4);
				scene.add(light);

				body.name = 'body';
				scene.add(body);
				// Create an AnimationMixer, and get the list of AnimationClip instances
				mixer = new THREE.AnimationMixer(body);
				const clips = object3d.animations;
				// Play a specific animation
				const clip = THREE.AnimationClip.findByName(clips, 'Armature|mixamo.com|Layer0');
				body.scale.multiplyScalar(10);
				const action = mixer.clipAction(clip);

				action.setLoop(THREE.LoopOnce);
				action.clampWhenFinished = true;
				action.play();

				// Play all animations
				// clips.forEach(function (clip) {
				// 	mixer.clipAction(clip).play();
				// });

				renderer.setAnimationLoop(animate);
			} catch (error) {
				console.log('error', error);
			}
		});
	};
	useEffect(() => {
		startLoading();

		//Setup Camera
		camera.position.set(1, 0.2, 7);

		renderer.setClearColor(0xffffff, 0);
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(window.innerWidth, window.innerHeight);

		init();

		window.addEventListener('resize', onWindowResize, false);
		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, []);

	const onWindowResize = () => {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize(window.innerWidth, window.innerHeight);
	};

	const update = () => {
		const dt = clock.getDelta();
		mixer.update(dt);

		renderer.render(scene, camera);
	};

	return <div ref={canvaRef} key={-1} style={backgorundStyle} />;
};

export default Canvas;

const backgorundStyle = {
	width: '100%',
	height: '100vh',
	zIndex: '-2',
	overflow: 'hidden'
};
