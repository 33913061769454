import React, { useContext, useRef } from 'react';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import { Parallax } from 'react-scroll-parallax';

import { Context } from '../contexts';
import Spinner from '../components/Spinner';
import InfoModal from '../components/InfoModal';
import Canvas from '../components/three/Canvas';

const Home = () => {
	const { state } = useContext(Context);
	const infoRef = useRef(null);

	const scrollToBottom = () => {
		infoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	return (
		<div className='page' style={{ width: '100%', overflowX: 'hidden' }}>
			{state === false && <Spinner />}
			<Canvas />
			<Parallax scale={[1, 0]} opacity={[1, 0]} shouldAlwaysCompleteAnimation>
				<div style={scrollDownButtonStyle} onClick={scrollToBottom}>
					Scroll down
					<KeyboardDoubleArrowDownRoundedIcon style={arrowDownStyle} />
				</div>
			</Parallax>
			<div ref={infoRef} style={{ width: '100%' }}>
				<InfoModal />
			</div>
		</div>
	);
};
export default Home;

const arrowDownStyle = {
	width: '100%',
	height: '128px',
	cursor: 'pointer',
	color: 'transparent',
	stroke: 'white',
	strokeWidth: '0.5px',
	filter: 'drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #ffffff)',
	animation: 'jump .8s infinite'
};
const scrollDownButtonStyle = {
	width: '100%',
	color: 'white',
	cursor: 'pointer',
	transform: 'translateY(-240px)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	// textShadow: '0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 40px #ffffff',
	fontFamily: '"Rock 3D", sans-serif',
	fontSize: '3rem',
	fontWeight: '900'
};
