/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import AwesomeSlider from 'react-awesome-slider';
import * as THREE from 'three';
import gsap from 'gsap';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import 'react-awesome-slider/dist/styles.css';

const webInfos = [
	{
		name: 'Body Clone 2018',
		img: require(`../assets/images/websiteImage/body-clone-2018.png`),
		url: 'https://siao.io',
		vector: { x: 0, y: -Math.PI / 2, z: 0 }
	},
	{
		name: 'Loka Location',
		img: require(`../assets/images/websiteImage/loka.png`),
		url: 'https://loka-location.com',
		vector: { x: 0, y: Math.PI / 2, z: 0 }
	},
	{
		name: 'IMRX Official Website',
		img: require(`../assets/images/websiteImage/imrx.png`),
		url: 'https://imrx.io',
		vector: { x: -Math.PI / 2, y: Math.PI, z: Math.PI }
	},
	{
		name: 'FUN',
		img: require(`../assets/images/websiteImage/fun.png`),
		url: 'https://fun.hsiao-li-chi.com',
		vector: { x: Math.PI / 2, y: Math.PI, z: Math.PI }
	},
	{
		name: 'Maxium - No Time for Names',
		img: require(`../assets/images/websiteImage/maxium.png`),
		url: 'https://siao.io/no-time-for-names/',
		vector: { x: 0, y: Math.PI, z: 0 }
	},
	{
		name: 'Body Clone 2018',
		img: require(`../assets/images/websiteImage/body-clone-2018.png`),
		url: 'https://siao.io',
		vector: { x: 0, y: -Math.PI / 2, z: 0 }
	}
];

const Web = () => {
	const canvaRef = useRef(null);
	const buttonRef = useRef(null);
	const renderer = useRef(new THREE.WebGLRenderer({ antialias: true, alpha: true })).current;
	const [currentItem, setCurrentItem] = useState(0);

	const scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

	const geometry = new THREE.BoxGeometry();

	const materials = [
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[0].img)
		}),
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[1].img)
		}),
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[2].img)
		}),
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[3].img)
		}),
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[4].img)
		}),
		new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load(webInfos[4].img)
		})
	];

	const cube = useRef(new THREE.Mesh(geometry, materials)).current;

	useEffect(() => {
		renderer.setSize(window.innerWidth, window.innerHeight);
		canvaRef.current.appendChild(renderer.domElement);

		scene.add(cube);

		camera.position.z = 2;
		camera.position.y = 0.8;
		camera.lookAt(cube.position);
		function animate() {
			renderer.render(scene, camera);
		}
		renderer.setAnimationLoop(animate);
	}, []);

	const onClickBackward = () => {
		const newItem = currentItem > 0 ? currentItem - 1 : 4;
		setCurrentItem(newItem);
		gsap.to(cube.rotation, { ...webInfos[newItem].vector, duration: 2 });
		var tl = gsap.timeline();
		tl.to(buttonRef.current, { height: 0, y: 20, duration: 0.25 }).to(buttonRef.current, {
			height: 120,
			y: 0,
			duration: 0.25
		});
	};
	const onClickForward = () => {
		const newItem = currentItem < 4 ? currentItem + 1 : 0;
		setCurrentItem(newItem);
		console.log(webInfos[newItem]);
		gsap.to(cube.rotation, { ...webInfos[newItem].vector, duration: 2 });
		var tl = gsap.timeline();
		tl.to(buttonRef.current, { height: 0, y: 20, duration: 0.25 }).to(buttonRef.current, {
			height: 120,
			y: 0,
			duration: 0.25
		});
	};

	return (
		<div style={backgorundStyle} ref={canvaRef}>
			<div className='arrows' style={arrowsContainerStyle}>
				<ArrowBackRoundedIcon style={arrowIconStyle} onClick={onClickBackward} />
				<div style={middleContainerStyle}>
					<div>{webInfos[currentItem].name}</div>
					<a
						ref={buttonRef}
						style={websiteButtonStyle}
						href={webInfos[currentItem].url}
						target='_blank'
						rel='noreferrer'
					>
						Go!
					</a>
				</div>
				<ArrowForwardRoundedIcon style={arrowIconStyle} onClick={onClickForward} />
			</div>
		</div>
	);
};
const websiteButtonStyle = {
	background: 'none',
	width: '120px',
	height: '120px',
	borderRadius: '60px',
	border: 'white solid 2px',
	color: 'white',
	fontFamily: '"Neucha", sans-serif',
	fontWeight: '900',
	fontSize: '2rem',
	textAlign: 'center',
	textDecoration: 'none',
	lineHeight: '120px',
	margin: '20px',
	overflow: 'hidden'
};

const middleContainerStyle = {
	fontFamily: '"Neucha", sans-serif',
	display: 'flex',
	justifyContent: 'flex-end',
	flexDirection: 'column',
	height: 'calc(100vh - 40px)',
	alignItems: 'center',
	fontWeight: '900'
};

const backgorundStyle = {
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'space-around',
	alignItems: 'center',
	flexWrap: 'wrap'
};

const arrowsContainerStyle = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '100vh',
	width: '100%',
	color: 'white',
	position: 'fixed'
};

const arrowIconStyle = {
	width: '40px',
	height: '40px',
	cursor: 'pointer'
};

export default Web;
