import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import Timeline from './Timeline';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

import './InfoModal.scss';

const InfoModal = () => {
	return (
		<div className='info-modal'>
			<div className='container'>
				<Parallax translateX={['-100px', '100px']} opacity={[0, 2]} shouldAlwaysCompleteAnimation>
					<p>
						Hsiao Li-Chi is a multimedia artist/web developer graduated from Berlin University of Art, her works take on
						a variety of forms such as Audio-Visual, XR, immersive experience and live performances.{' '}
					</p>
				</Parallax>
				<Parallax translateX={['100px', '-100px']} opacity={[0, 2]} shouldAlwaysCompleteAnimation>
					<p>Her works are mostly about the relationship of herself, the internet, and social society.</p>
					<p>
						Recently, her works reach the field of XR and blockchain, trying to imagine the virtual social activity of
						nearly future.
					</p>
				</Parallax>
			</div>
			<Timeline />
			<div className='container icons'>
				<Parallax scale={[0, 1]} opacity={[0, 1]} shouldAlwaysCompleteAnimation>
					<a href='https://www.instagram.com/siao.lichi/' target='_blank' rel='noreferrer'>
						<InstagramIcon className='icon' />
					</a>
					<a href='https://www.facebook.com/lllllllllllili' target='_blank' rel='noreferrer'>
						<FacebookIcon className='icon' />
					</a>
					<a href='https://www.youtube.com/channel/UCaDexFYvLULF_CNz4diY3eQ' target='_blank' rel='noreferrer'>
						<YouTubeIcon className='icon' />
					</a>
				</Parallax>
			</div>
		</div>
	);
};

export default InfoModal;
